/* eslint-disable */
import React, { useState, useEffect } from "react";
import close from "../../../../../assets/icons/close.png";
import AISettings from "../assets/icons/AISettings.png";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import "../assets/stylesheets/editDesign.scss";
import { Dialog } from "@mui/material";
import AISettingsModel from "./AISettingsModel";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import ReactJsxParser from "react-jsx-parser";
import { generateTemplate } from "../../AddCampaign/api/addCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";

export default function EditDesign({
  selectedDesign,
  setGenerateImage,
  setEditDesign,
  setUsedDesign
}) {
  const location = useLocation();
  const navigate = useNavigate();

  let {
    selectedImage,
    template,
    selectedRandomObject,
    selectedChannels,
    multiSelectTypes,
    selectedPersonas,
    navigationFrom,
    generatedHeading,
    generatedContent,
    generatedCta
  } = location.state || {};

  console.log(
    "selectedImage",
    selectedImage,
    "selectedRandomObject",
    selectedRandomObject,
    "template",
    template,
    "navigationFrom",
    navigationFrom,
    generatedHeading,
    generatedContent,
    generatedCta
  );

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [aiSettingsPopUp, setAISettingsPopUp] = useState(false);

  const [field, setField] = useState("");

  const [loading, setLoading] = useState(false);
  const [heading, setHeading] = useState("");
  const [content, setContent] = useState("");
  const [cta, setCta] = useState("");
  const [opText, setOpText] = useState("");
  const [errors, setErrors] = useState("");

  const handleAISettingsPopUpOpen = (label) => {
    setField(label);
    setAISettingsPopUp(true);
  };

  const handleAISettingsPopUpClose = () => {
    setAISettingsPopUp(false);
  };

  const handleInputChange = (setter, fieldName) => (e) => {
    setter(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!heading.trim()) {
      newErrors.heading = (
        <p className="input-error-text">
          {t("manageCampaign.headingRequired")}
        </p>
      );
    }

    if (!content.trim()) {
      newErrors.content = (
        <p className="input-error-text">
          {t("manageCampaign.contentRequired")}
        </p>
      );
    }

    if (!cta.trim()) {
      newErrors.cta = (
        <p className="input-error-text">{t("manageCampaign.ctaRequired")}</p>
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (selectedRandomObject) {
      setHeading(selectedRandomObject?.title);
      setContent(selectedRandomObject?.content);
      setCta(selectedRandomObject?.cta);
    } else if (navigationFrom === "designPage") {
      setHeading(generatedHeading);
      setContent(generatedContent);
      setCta(generatedCta);
    }
  }, [selectedRandomObject, navigationFrom]);

  console.log("selectedRandomObject", selectedRandomObject);

  const handleGenerateTemplate = async () => {
    setLoading(true);
    const payload = [
      {
        content: content,
        cta: cta,
        imageUrl: selectedImage
          ? selectedImage?.src
          : selectedRandomObject?.imageUrl,
        title: heading
      }
    ];
    try {
      const templateResponse = await generateTemplate(payload);
      console.log("templateResponse", templateResponse);
      const originalTemplates = templateResponse?.data?.templates;
      const cleanTemplates = templateResponse?.data?.templates?.map(
        (template) =>
          template
            .replace(/\\n/g, "")
            .replace(/\\t/g, "")
            .replace(/\\"/g, '"')
            .replace(/\\'/g, "'")
      );
      if (templateResponse?.status === 200) {
        Toastr.success("Success");
        navigate("/manage/design-preview", {
          state: {
            selectedRandomObject,
            cleanTemplates,
            originalTemplates,
            selectedChannels,
            multiSelectTypes,
            selectedPersonas,
            template: templateResponse?.data?.templates[0],
            selectedImage,
            content,
            cta,
            heading
          }
        });
      } else {
        Toastr.error("Template generation failed.");
      }
    } catch (error) {
      setLoading(false);
      Toastr.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setErrors("");
      handleGenerateTemplate();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  console.log("navigationFrom", navigationFrom);

  return (
    <>
      <AuthorizedHeader />

      <div className="edit-design container">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            <div className="flex-between">
              <div className="modules-heading">
                <p className="modules-heading">
                  {t("manageCampaign.textImages")}
                </p>
              </div>
              <div className="close-icon">
                <img
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      navigate(-1);
                    }
                  }}
                  src={close}
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="close-icon-img"
                />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex-space">
                {/* Input fields */}
                <div className="inputs-width">
                  <div className={"input-margin"}>
                    {/* Heading */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.heading")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            errors.heading
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={heading}
                          onChange={handleInputChange(setHeading, "heading")}
                        />
                        {errors.heading}
                      </div>
                      {/* <img
                        src={AISettings}
                        onClick={() => {
                          // (e) =>
                          // handleIconClick(e, `AI settings for ${label}`)
                          handleAISettingsPopUpOpen("Heading");
                        }}
                        className="ai-settings-icon"
                      /> */}
                    </div>
                    {/* Heading ends */}

                    {/* Content */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.content")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            errors.content
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={content}
                          onChange={handleInputChange(setContent, "content")}
                        />

                        {errors.content}
                      </div>
                      {/* <img
                        src={AISettings}
                        onClick={() => {
                          // (e) =>
                          // handleIconClick(e, `AI settings for ${label}`)
                          handleAISettingsPopUpOpen("Content");
                        }}
                        className="ai-settings-icon"
                      /> */}
                    </div>
                    {/* Content ends */}

                    {/* Optional text */}
                    {/* <div className="generated-designs-campaign-name">
                      {t("manageCampaign.opText")}
                    </div> */}
                    <div className="flex">
                      {/* <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            errors.opText
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={opText}
                          onChange={handleInputChange(setOpText, "opText")}
                        />
                        {errors.opText}
                      </div> */}
                      {/* <img
                        src={AISettings}
                        onClick={() => {
                          // (e) =>
                          // handleIconClick(e, `AI settings for ${label}`)
                          handleAISettingsPopUpOpen("Optional text");
                        }}
                        className="ai-settings-icon"
                      /> */}
                    </div>
                    {/* Optional text ends */}

                    {/* Call to action button */}
                    <div className="generated-designs-campaign-name">
                      {t("manageCampaign.ctaButton")}
                    </div>
                    <div className="flex">
                      <div className="input-field-width">
                        <input
                          type="text"
                          className={
                            errors.cta
                              ? "input-field-common-error"
                              : "input-field-common input-placeholder"
                          }
                          value={cta}
                          onChange={handleInputChange(setCta, "cta")}
                        />

                        {errors.cta}
                      </div>
                      {/* <img
                        src={AISettings}
                        onClick={() => {
                          // (e) =>
                          // handleIconClick(e, `AI settings for ${label}`)
                          handleAISettingsPopUpOpen("Call to action button");
                        }}
                        className="ai-settings-icon"
                      /> */}
                    </div>
                  </div>
                </div>

                {/* Input fields ends */}

                {/* Previewed image */}
                <div className="preview-campaign-background">
                  <div>
                    <div className="preview-campaign-text">
                      {t("manageCampaign.preview")}
                    </div>
                  </div>
                  <div>
                    {/* <PreviewedDesignImageEdit
                  heading={selectedDesign.heading}
                  title={selectedDesign.title}
                  subtitle={selectedDesign.subtitle}
                  content={selectedDesign.content}
                  actionButton={selectedDesign.actionButton}
                  bgcolor={selectedDesign.bgcolor}
                /> */}
                    {/* {selectedImage ? (
                      <img
                        src={selectedImage?.src}
                        style={{
                          width: "320px",
                          height: "390px",
                          objectFit: "cover"
                        }}
                      />
                    ) : template ? (
                      <div>
                        <ReactJsxParser components={{}} jsx={template} />
                      </div>
                    ) : navigationFrom == "designPage" ? (
                      <div>
                        <ReactJsxParser components={{}} jsx={template} />
                      </div>
                    ) : null} */}

                    {(navigationFrom === "designPage" && (
                      <div>
                        <ReactJsxParser components={{}} jsx={template} />
                      </div>
                    )) ||
                      (selectedImage ? (
                        <img
                          src={selectedImage.src}
                          style={{
                            width: "320px",
                            height: "390px",
                            objectFit: "cover"
                          }}
                        />
                      ) : (
                        template && (
                          <div>
                            <ReactJsxParser components={{}} jsx={template} />
                          </div>
                        )
                      ))}
                  </div>
                </div>
                {/* Previewed image ends */}
              </div>

              <div className="flex-center buttons-padding">
                {loading ? (
                  <Loader text={t("manageCampaign.generatingImages")} />
                ) : (
                  <>
                    <div className="cancel-button-padding-right">
                      <ButtonLight
                        buttonText={t("common.confirmation.cancel")}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    <ButtonDark
                      isSubmit
                      buttonText={t("common.confirmation.next")}
                    />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>

        <Dialog
          open={aiSettingsPopUp}
          onClose={handleAISettingsPopUpClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              minHeight: "700px",
              overflow: "hidden"
            }
          }}
        >
          <AISettingsModel
            close={close}
            field={field}
            handleClose={handleAISettingsPopUpClose}
          />
        </Dialog>
        {/* Popover for AI Settings ends */}
      </div>
    </>
  );
}
