/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import ReactJsxParser from "react-jsx-parser";
import close from "../../../../../assets/icons/close.png";
import "../assets/stylesheets/openedImageModel.scss";

export default function OpenedImageModel({
  setOpenedImage,
  selectedImage,
  selectedName,
  selectedDimension
}) {
  const { t } = useTranslation();

  return (
    <div className="opened-image-model">
      <div className="flex-space preview-close">
        <div className="preview-text">{t("manageCampaign.preview")}</div>
        <div
          onClick={() => {
            setOpenedImage(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(false);
            }
          }}
        >
          <img src={close} className="close-img" alt="close" />
        </div>
      </div>

      <div className="flex-justify-center image-section-main-div">
        <ReactJsxParser components={{}} jsx={selectedImage} />
      </div>
      {/* Image section ends */}

      {/* Image name, dimensions and download icon */}
      <div className="name-dimensions">
        <div className="flex-space">
          <div className="image-name">{selectedName}</div>
        </div>
        <div className="dimensions">{selectedDimension}</div>
      </div>
      {/* Image name, dimensions and download icon */}
    </div>
  );
}
