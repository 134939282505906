/* eslint-disable */
import React from "react";
import Selectpersona from "../../../../../assets/icons/Selectpersona.png";
import "../assets/stylesheets/selectPersona.scss";

export default function SelectPersona({
  isHoveredPersona,
  handleMouseEnterPersona,
  handleMouseLeavePersona,
  selectedPersona,
  t,
  newPersonaTypes,
  handlePersonaChange,
  personas,
  selectedPersonas,
  handleCheckboxChange,
  multiSelectTypes
}) {
  return (
    <div
      className={`select-types ${
        isHoveredPersona || multiSelectTypes?.length > 0 ? "isPersona" : ""
      }`}
      onMouseEnter={handleMouseEnterPersona}
      onMouseLeave={handleMouseLeavePersona}
    >
      <div className="step-text">{t("manageCampaign.step")} 3</div>

      {multiSelectTypes?.length > 0 ? (
        <div>
          {/* <div className="select-types-text">
            A. {t("manageCampaign.selectType")}
          </div> */}
          {/* Dropdown */}
          {/* <div className="types-dropdown">
            <DropdownComponent
              value={
                selectedPersona || t("tagManager.addTagForm.selectPlaceholder")
              }
              options={newPersonaTypes}
              onChange={handlePersonaChange}
              isRequired={false}
              defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
            />
          </div> */}
          {/* Dropdown ends */}
          <div className="select-types-text">
            {t("manageCampaign.selectPersona")}
          </div>
          {/* Checkbox */}
          <div className="scrollable-container-new">
            {personas.map((persona) => (
              <div
                className={`flex-between persona-checkbox ${
                  selectedPersonas?.includes(persona?.personaId)
                    ? "isSelected"
                    : ""
                }`}
              >
                <div key={persona.personaId} className="flex-center-align ">
                  <img
                    src={persona.personaIcon}
                    alt={persona.personaName}
                    className="persona-icon"
                  />

                  <span
                    className={`persona-name ${
                      selectedPersonas?.includes(persona?.personaId)
                        ? "selected"
                        : ""
                    }`}
                  >
                    {persona.personaName}
                  </span>
                </div>

                <input
                  type="checkbox"
                  checked={selectedPersonas.includes(persona.personaId)}
                  onChange={() => handleCheckboxChange(persona.personaId)}
                />
              </div>
            ))}
          </div>
          {/* Checkbox ends */}
        </div>
      ) : (
        <div className="select-types-text">Select persona</div>
      )}

      {multiSelectTypes?.length == 0 && (
        <div className="flex-center">
          <img src={Selectpersona} />
        </div>
      )}
    </div>
  );
}
