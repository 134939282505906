/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ButtonDark } from "../../../../../components/inputElements/buttons/MainButton";
import "../../GeneratedCampaign/assets/stylesheets/generatedCampaignFinal.scss";
import "../assets/stylesheets/savedCampaign.scss";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import collapse from "../assets/icons/collapse.png";
import expand from "../assets/icons/expand.png";
import { isMobile } from "react-device-detect";
import CampaignTile from "./CampaignTile";
import WebAdvertisement from "./WebAdvertisement";
import GoogleMarketingImages from "./GoogleMarketingImages";
import { Dialog } from "@mui/material";
import OpenedImageModel from "./OpenedImageModel";
import FacebookAndInstaAds from "./FacebookAndInstaAds";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteComponent from "../../../../../components/DeletePopUp/DeleteComponent";
import { useSelector } from "react-redux";
import {
  changeCampaignStatus,
  deleteCampaigns
} from "../../AddCampaign/api/addCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import ToggleSwitch from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

export default function SavedCampaign() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const { websiteID } = useSelector((state) => state.generalData);

  const { selectedCampaign } = location.state || {};

  const [campaignBanners, setCampaignBanners] = useState([]);

  const multiDimensionAds = selectedCampaign?.advertisment_image?.map(
    (item) => item.template
  );

  // Function to remove <a> tags
  const removeAttributesFromATags = (webTemplates) => {
    return webTemplates?.map((template) =>
      template.replace(/<a\b[^>]*>/gi, (match) => {
        // Remove href, target, and rel attributes by replacing them with an empty string
        return match
          .replace(/\s*href="[^"]*"/gi, "") // Removes the href attribute
          .replace(/\s*target="[^"]*"/gi, "") // Removes the target attribute
          .replace(/\s*rel="[^"]*"/gi, ""); // Removes the rel attribute
      })
    );
  };

  useEffect(() => {
    const updatedTemplates = removeAttributesFromATags(multiDimensionAds);
    setCampaignBanners(updatedTemplates);
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [showWebAds, setShowWebAds] = useState(true);
  const [openedImage, setOpenedImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedName, setSelectedName] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [changeCampaignLoading, setChangeCampaignLoading] = useState(false);
  const [deleteCampaignsList, setDeleteCampaignsList] = useState([]);

  const [campaignEnabled, setCampaignEnabled] = useState(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    setDeleteCampaignsList({
      campaign_name: selectedCampaign?.campaign_name,
      channel_id: selectedCampaign?.channel_id
    });
  }, [selectedCampaign]);

  const handleDeleteCampaigns = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        website_id: websiteID,
        campaignData: [deleteCampaignsList]
      };
      const res = await deleteCampaigns(payload);
      if (res?.data?.success) {
        Toastr.success(res?.data?.message);
        setShowDeletePopup(false);
        navigate(-1);
      }
    } catch (error) {
      setDeleteLoading(false);
      Toastr.error("Failed to delete");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleChangeCampaign = async () => {
    setCampaignEnabled(!campaignEnabled);
    setChangeCampaignLoading(true);
    try {
      const payload = {
        campaign_status: !campaignEnabled ? 1 : 0,
        channel_id: selectedCampaign?.channel_id,
        website_id: websiteID,
        campaign_name: selectedCampaign?.campaign_name
      };

      const res = await changeCampaignStatus(payload);
      if (res?.data?.campaignsData?.success) {
        Toastr.success(res?.data?.campaignsData?.message);
      }
    } catch (error) {
      setChangeCampaignLoading(false);
      Toastr.error("Failed to delete");
    } finally {
      setChangeCampaignLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCampaign?.campaign_status === 1) {
      setCampaignEnabled((state) => true);
    } else {
      setCampaignEnabled((state) => false);
    }
  }, [selectedCampaign]);

  return (
    <>
      <AuthorizedHeader />
      <div className="container saved-campaign">
        <div className="generated-designs background-box">
          <div className="main-page-margins">
            <div className="flex-column-height">
              <div className="saved-campaign">
                <div className="flex-between">
                  <div>
                    <p className="modules-heading">
                      {selectedCampaign?.campaign_name}
                    </p>
                  </div>
                  <div
                    style={{
                      marginTop: "20px"
                    }}
                    className="flex top-margin"
                  >
                    <button
                      style={
                        !isMobile
                          ? { backgroundSize: "50px" }
                          : { backgroundSize: "40px" }
                      }
                      className="delete-multiple"
                      type="button"
                      onClick={() => {
                        setShowDeletePopup(true);
                      }}
                    />
                    <div className="bottom-margin">
                      <ButtonDark
                        buttonText={t("ManageApi.back")}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                  </div>

                  {showDeletePopup && (
                    <DeleteComponent
                      onCancel={() => setShowDeletePopup(false)}
                      loading={deleteLoading}
                      onConfirmDelete={() => {
                        handleDeleteCampaigns();
                      }}
                    />
                  )}
                </div>

                <div className="bottom-tile-margin">
                  <CampaignTile
                    isHovered={isHovered}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    selectedCampaign={selectedCampaign}
                  />
                  <div
                    style={{
                      paddingTop: "2%"
                    }}
                  >
                    {changeCampaignLoading ? (
                      <TailSpin
                        height="30"
                        width="30"
                        color={themeColors.primaryColor}
                        ariaLabel="tail-spin-loading"
                        radius=".25"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible
                      />
                    ) : (
                      <ToggleSwitch
                        isToggled={campaignEnabled}
                        setIsToggled={setCampaignEnabled}
                        label={t("manageCampaign.enableCampaign")}
                        onPress={handleChangeCampaign}
                      />
                    )}
                  </div>
                  {/* Ads scroll container */}
                  <div className="scroll-container">
                    {/* Web Ads */}
                    <div
                      className="flex-pointer"
                      onClick={() => {
                        setShowWebAds(!showWebAds);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        // Handling keyboard events
                        if (e.key === "Enter" || e.key === " ") {
                          setShowWebAds(!showWebAds);
                        }
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "20px"
                        }}
                        className="images-text"
                      >
                        {t("manageCampaign.webAdvertisement")}
                      </div>

                      {showWebAds ? (
                        <img src={expand} className="expand-collapse-icons" />
                      ) : (
                        <img src={collapse} className="expand-collapse-icons" />
                      )}
                    </div>

                    {showWebAds ? (
                      <div>
                        <WebAdvertisement
                          // webImages={multiDimensionAds}
                          webImages={campaignBanners}
                          orginalTemplate={multiDimensionAds}
                          setOpenedImage={setOpenedImage}
                          setSelectedImage={setSelectedImage}
                          setSelectedName={setSelectedName}
                          setSelectedDimension={setSelectedDimension}
                          selectedCampaign={selectedCampaign}
                          navigationFrom="save"
                        />
                      </div>
                    ) : null}
                    {/* Web Ads end */}

                    {/* Google ads */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setShowGoogleAds(!showGoogleAds);
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "20px"
                        }}
                        className="images-text"
                      >
                      </div>

                      {showGoogleAds ? (
                        <img
                          src={expand}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      ) : (
                        <img
                          src={collapse}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      )}
                    </div> */}

                    {/* {showGoogleAds ? (
                      <div>
                        <GoogleMarketingImages
                          setOpenedImage={setOpenedImage}
                          setSelectedImage={setSelectedImage}
                        />
                      </div>
                    ) : null} */}
                    {/* Google ads end */}

                    {/* Facebook and insta ads */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setShowFacebookAds(!showFacebookAds);
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "20px"
                        }}
                        className="images-text"
                      >
                        Facebook marketing images
                      </div>

                      {showFacebookAds ? (
                        <img
                          src={expand}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      ) : (
                        <img
                          src={collapse}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      )}
                    </div> */}

                    {/* {showFacebookAds ? (
                      <div>
                        <FacebookAndInstaAds
                          setOpenedImage={setOpenedImage}
                          setSelectedImage={setSelectedImage}
                        />
                      </div>
                    ) : null} */}
                    {/* Facebook and insta ads end */}
                  </div>
                  {/* Ads scroll container ends */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={openedImage}
          onClose={() => {
            setOpenedImage(false);
            setSelectedImage(null);
          }}
          maxWidth="md"
          fullWidth
          className="user-management"
          PaperProps={{
            style: {
              minHeight: "12vw",
              padding: "0px"
            }
          }}
        >
          <OpenedImageModel
            selectedImage={selectedImage}
            setOpenedImage={setOpenedImage}
            selectedName={selectedName}
            selectedDimension={selectedDimension}
          />
        </Dialog>
      </div>
    </>
  );
}
