/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import collapse from "../../SavedCampaign/assets/icons/collapse.png";
import expand from "../../SavedCampaign/assets/icons/expand.png";
import "../assets/stylesheets/generatedCampaignFinal.scss";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import WebAdvertisement from "../../SavedCampaign/components/WebAdvertisement";
import { Dialog } from "@mui/material";
import OpenedImageModel from "../../SavedCampaign/components/OpenedImageModel";
import { addCampaignImages } from "../../AddCampaign/api/addCampaignAPI";
import { useSelector } from "react-redux";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";

export default function GeneratedCampaignFinal({
  setGenerateCampaign,
  setEditDesign,
  setAddCampaign,
  setGenerateImage
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { websiteID } = useSelector((state) => state.generalData);

  const {
    selectedRandomObject,
    selectedChannels,
    multiDimensionAds,
    template,
    campaignName,
    multiSelectTypes,
    selectedPersonas,
    adRedirectUrl,
    ctaButtonUrl,
    cleanTemplates,
    selectedImageNew,
    content,
    cta,
    heading
  } = location.state || {};

  console.log(
    "selectedRandomObject",
    selectedRandomObject,
    "selectedImageNew",
    selectedImageNew
  );

  const [loading, setLoading] = useState(false);

  const [showWebAds, setShowWebAds] = useState(true);
  const [openedImage, setOpenedImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [campaignBanners, setCampaignBanners] = useState([]);

  const [campaignInfo, setCampaignInfo] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");

  const webTemplates = multiDimensionAds?.website?.map((web) => web?.template);

  const encodedAds = multiDimensionAds?.website?.map((ad) => ({
    imageLink: selectedImageNew
      ? selectedImageNew?.src
      : selectedRandomObject?.imageUrl,
    name: ad?.name,
    dimensions: ad?.dimensions,
    template: btoa(ad?.template)
  }));

  useEffect(() => {
    const newCampaigns = selectedChannels.map((channel) => {
      // Extract type_ids from the channel's types
      const channelTypeIds = channel.type.map((type) => type.type_id);

      // Filter the types based on multiSelectTypes for the current channel
      const relevantTypes = multiSelectTypes.filter((type) =>
        channelTypeIds.includes(type.type_id)
      );

      return {
        website_id: websiteID,
        channel_id: channel.channel_id,
        type_id: relevantTypes.map((type) => type.type_id),
        campaign_name: campaignName,
        redirect_url: adRedirectUrl,
        cta_url: ctaButtonUrl,
        persona: selectedPersonas,
        // advertisment_image: encodedAds.map((tmpl) => ({
        //   imageLink: selectedRandomObject.imageUrl,
        //   template: tmpl
        // })),
        advertisment_image: encodedAds,
        title: selectedImageNew ? heading : selectedRandomObject?.title,
        content: selectedImageNew ? content : selectedRandomObject?.content,
        cta: selectedImageNew ? cta : selectedRandomObject?.cta
      };
    });

    setCampaignInfo(newCampaigns);
  }, [
    selectedChannels,
    selectedPersonas,
    multiSelectTypes,
    selectedRandomObject
  ]);

  const removeAttributesFromATags = (webTemplates) => {
    return webTemplates?.map((template) =>
      template.replace(/<a\b[^>]*>/gi, (match) => {
        // Remove href, target, and rel attributes by replacing them with an empty string
        return match
          .replace(/\s*href="[^"]*"/gi, "") // Removes the href attribute
          .replace(/\s*target="[^"]*"/gi, "") // Removes the target attribute
          .replace(/\s*rel="[^"]*"/gi, ""); // Removes the rel attribute
      })
    );
  };

  useEffect(() => {
    const updatedTemplates = removeAttributesFromATags(webTemplates);
    setCampaignBanners(updatedTemplates);
  }, []);

  const saveCampaign = async () => {
    setLoading(true);
    try {
      const response = await addCampaignImages(campaignInfo);
      if (response.data?.success) {
        Toastr.success(response.data?.message);
        navigate("/manage/campaign");
      }
    } catch (error) {
      setLoading(false);
      Toastr.error("Error");
    } finally {
      setLoading(false);
    }
  };

  console.log(
    "campaignBanners====>",
    campaignBanners,
    "multiDimensionAds====>",
    multiDimensionAds,
    "selectedImage",
    selectedImage,
    "heading",
    heading,
    "content",
    content,
    "cta",
    cta
  );

  return (
    <>
      <AuthorizedHeader />

      <div className="container">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            <div className="flex-column-height">
              <div>
                <div className="modules-heading">
                  <p className="modules-heading">{campaignName}</p>
                </div>

                {/* <div className="images-text">
                  <p>Google images</p>gen
                </div>

                <div className="images-text">
                  <p>Facebook images</p>
                </div> */}

                {/* Ads scroll container */}
                <div className="scroll-container">
                  {/* Web Ads */}
                  <div
                    className="flex-pointer"
                    onClick={() => {
                      setShowWebAds(!showWebAds);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      // Handling keyboard events
                      if (e.key === "Enter" || e.key === " ") {
                        setShowWebAds(!showWebAds);
                      }
                    }}
                  >
                    <div className="images-text">
                      {t("manageCampaign.webAdvertisement")}
                    </div>

                    {showWebAds ? (
                      <img src={expand} className="expand-collapse-icons" />
                    ) : (
                      <img src={collapse} className="expand-collapse-icons" />
                    )}
                  </div>

                  {showWebAds ? (
                    <div>
                      <WebAdvertisement
                        // webImages={multiDimensionAds}
                        orginalTemplateCreate={multiDimensionAds}
                        webImages={campaignBanners}
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                        setSelectedName={setSelectedName}
                        setSelectedDimension={setSelectedDimension}
                        navigationFrom="createFlow"
                      />
                    </div>
                  ) : null}
                  {/* Web Ads end */}

                  {/* Google ads */}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setShowGoogleAds(!showGoogleAds);
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "20px"
                      }}
                      className="images-text"
                    >
                      Google ads
                    </div>

                    {showGoogleAds ? (
                      <img
                        src={expand}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    ) : (
                      <img
                        src={collapse}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    )}
                  </div> */}

                  {/* {showGoogleAds ? (
                    <div>
                      <GoogleMarketingImages
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  ) : null} */}
                  {/* Google ads end */}

                  {/* Facebook and insta ads */}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setShowFacebookAds(!showFacebookAds);
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "20px"
                      }}
                      className="images-text"
                    >
                      Facebook marketing images
                    </div>

                    {showFacebookAds ? (
                      <img
                        src={expand}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    ) : (
                      <img
                        src={collapse}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    )}
                  </div> */}

                  {/* {showFacebookAds ? (
                    <div>
                      <FacebookAndInstaAds
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  ) : null} */}
                  {/* Facebook and insta ads end */}
                </div>
                {/* Ads scroll container ends */}
              </div>

              {loading ? (
                <Loader text={t("manageCampaign.savingCampaign")} />
              ) : (
                <div className="flex-center buttons-top">
                  <div className="cancel-button-padding-right">
                    <ButtonLight
                      buttonText={t("manageCampaign.editCampaign")}
                      onClick={() => {
                        navigate("/manage/edit-design", {
                          state: {
                            selectedRandomObject,
                            cleanTemplates,
                            template,
                            selectedChannels,
                            multiSelectTypes,
                            selectedPersonas,
                            navigationFrom: selectedImageNew
                              ? "designPage"
                              : "",
                            generatedHeading: heading,
                            generatedContent: content,
                            generatedCta: cta,
                            selectedImage: selectedImageNew
                          }
                        });
                      }}
                    />
                  </div>
                  <ButtonDark
                    buttonText={t("manageCampaign.saveCampaign")}
                    onClick={saveCampaign}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openedImage}
        onClose={() => {
          setOpenedImage(false);
          setSelectedImage(null);
        }}
        maxWidth="md"
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            minHeight: "12vw",
            padding: "0px"
          }
        }}
      >
        <OpenedImageModel
          selectedImage={selectedImage}
          setOpenedImage={setOpenedImage}
          selectedName={selectedName}
          selectedDimension={selectedDimension}
        />
      </Dialog>
    </>
  );
}
