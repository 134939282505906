import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "../../features/Settings/Profile";
import Notification from "../../features/Settings/Notification";
import UserManagement from "../../features/Settings/UserManagement";
import Categories from "../../features/Settings/Categories";
import Privacy from "../../features/Settings/Privacy";
import Billing from "../../features/Settings/Billing";
import Subscription from "../../features/Settings/Subscription";
import RestrictedPage from "../../features/Placeholders/RestrictedPage";
import PersonaManagement from "../../features/Settings/PersonaManagement";

function SettingsRoutes() {
  const { role: userRole } = useSelector((state) => state.generalData);
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="profile" element={<Profile />} />
      {/* <Route path="notification" element={<Notification />} /> */}
      {userRole !== 2 && userRole !== 4 ? (
        <Route path="notification" element={<Notification />} />
      ) : (
        <Route path="notification" element={<RestrictedPage />} />
      )}
      {userRole !== 2 &&
      userRole !== 3 &&
      userRole !== 4 &&
      userRole !== 6 &&
      userRole !== 5 ? (
        <Route path="user-management" element={<UserManagement />} />
      ) : (
        <Route path="user-management" element={<RestrictedPage />} />
      )}
      {userRole !== 2 ? (
        <Route path="categories" element={<Categories />} />
      ) : (
        <Route path="categories" element={<RestrictedPage />} />
      )}
      {userRole === 1 || userRole === 7 ? (
        <Route path="privacy" element={<Privacy />} />
      ) : (
        <Route path="privacy" element={<RestrictedPage />} />
      )}
      {userRole !== 2 && userRole !== 4 ? (
        <Route path="billing" element={<Billing />} />
      ) : (
        <Route path="billing" element={<RestrictedPage />} />
      )}
      {userRole !== 2 && userRole !== 4 ? (
        <Route path="subscription" element={<Subscription />} />
      ) : (
        <Route path="subscription" element={<RestrictedPage />} />
      )}
      {userRole !== 2 &&
      userRole !== 3 &&
      userRole !== 4 &&
      userRole !== 6 &&
      userRole !== 5 ? (
        <Route path="persona-management" element={<PersonaManagement />} />
      ) : (
        <Route path="persona-management" element={<RestrictedPage />} />
      )}
    </Routes>
  );
}

export default SettingsRoutes;
