function dropdownData(t) {
  const DashboardMenu = [
    {
      id: 1,
      path: "/analytics",
      name: t("dashboardMenu.analytics")
    },
    {
      id: 7,
      path: "/pms",
      name: t("dashboardMenu.pmsData")
    },
    {
      id: 2,
      path: "/personas",
      name: t("dashboardMenu.personas")
    },
    {
      id: 3,
      path: "/customers",
      name: t("dashboardMenu.customers")
    },
    // {
    //   id: 4,
    //   path: "/sessions",
    //   name: t("dashboardMenu.sessions")
    // },
    // {
    //   id: 5,
    //   path: "/widget/performance",
    //   name: t("dashboardMenu.widgetPerformance")
    // },
    {
      id: 6,
      path: "/campaign/performance",
      name: t("dashboardMenu.campaignPerformance")
    },
    {
      id: 8,
      path: "/isichat",
      name: t("dashboardMenu.isiChat")
    }
  ];

  const IntegrationMenu = [
    {
      id: 1,
      path: "/dataupload",
      name: t("integrationMenu.dataUpload")
    },
    {
      id: 2,
      path: "/manage/api",
      name: t("integrationMenu.manageApi")
    },
    {
      id: 3,
      path: "/dataexport",
      name: t("integrationMenu.dataExport")
    }
  ];

  const CampaignMenu = [
    {
      id: 1,
      path: "/add/networks",
      name: t("campaignMenu.addAdNetworks")
    },
    {
      id: 2,
      path: "/manage/campaign",
      name: t("campaignMenu.manageCampaign")
    },
    {
      id: 3,
      path: "/manage/widgets",
      name: t("campaignMenu.manageCtaWidgets")
    },
    {
      id: 4,
      path: "/tag/manager/settings",
      name: t("campaignMenu.tagManager")
    },
    {
      id: 5,
      path: "/uploadimage",
      name: t("uploadImages.uploadImages")
    }
  ];

  return { DashboardMenu, IntegrationMenu, CampaignMenu };
}

export default dropdownData;
