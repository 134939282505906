/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import i18n from "./config/i18n";
import LocalStorage from "./utils/LocalStorgae";
import "./App.scss";
import Footer from "./components/layouts/Footer";
import Onboarding from "./pages/Authorized/Onboarding";
import PrivateRoutes from "./components/PrivateRoutes";
import Analytics from "./pages/Authorized/Dashboard";
import TagManager from "./pages/Authorized/TagManager";
import Settings from "./pages/Authorized/Settings";
import PageHandler from "./pages/Authorized/PageHandler";
import Personas from "./pages/Authorized/Personas";
import Customers from "./pages/Authorized/Customers";
import Sessions from "./pages/Authorized/Sessions";
import WidgetPerformance from "./pages/Authorized/WidgetPerformance";
import CampaignPerformance from "./pages/Authorized/CampaignPerformance";
import PmsDashboard from "./pages/Authorized/PmsDashboard";
import MyWebsites from "./features/MyWebsites";
import RememberMeLogin from "./components/RememberMeLogin";
import RestrictedAccess from "./pages/Authorized/RestrictedAccess";
import NewSignupPage from "./pages/Unauthorized/NewSignupPage";
import NewLoginPage from "./pages/Unauthorized/NewLoginPage";
import ForgotPasswordPage from "./pages/Unauthorized/ForgotPasswordPage";
import ResetPasswordPage from "./pages/Unauthorized/ResetPasswordPage";
import DataUpload from "./pages/Authorized/DataUpload";
import DataExport from "./pages/Authorized/DataExport";
import ManageApi from "./pages/Authorized/ManageApi";
import AddAdNetworks from "./pages/Authorized/AddAdNetworks";
import ManageCampaign from "./pages/Authorized/ManageCampaign";
import ManageCtaWidgets from "./pages/Authorized/ManageCtaWidgets";
import IsiChat from "./pages/Authorized/IsiChat";
import languageApi from "./api/language";
import storage from "./utils/storage";
import UploadImages from "./pages/Authorized/UploadImages";
import ManageApiService from "./pages/Authorized/ManageApiService";
/* eslint-disable-next-line no-restricted-imports */
import AddCampaignComponent from "./features/ManageCampaign/components/AddCampaign/components/AddCampaignComponent";
/* eslint-disable-next-line no-restricted-imports */
import GeneratedDesignsComponent from "./features/ManageCampaign/components/GeneratedDesigns/components/GeneratedDesignsComponent";
/* eslint-disable-next-line no-restricted-imports */
import UsedDesignPreviewComponent from "./features/ManageCampaign/components/PreviewedDesign/components/UsedDesignPreviewComponent";
/* eslint-disable-next-line no-restricted-imports */
import GeneratedCampaignFinal from "./features/ManageCampaign/components/GeneratedCampaign/components/GeneratedCampaignFinal";
/* eslint-disable-next-line no-restricted-imports */
import SavedCampaign from "./features/ManageCampaign/components/SavedCampaign/components/SavedCampaign";
/* eslint-disable-next-line no-restricted-imports */
import EditDesign from "./features/ManageCampaign/components/EditDesign/components/EditDesign";

function App() {
  const { role: userRole } = useSelector((state) => state.generalData);
  const { toggleState } = useSelector((state) => state.generalData);
  // const dispatch = useDispatch();
  const { websitesList } = useSelector((state) => state.generalData);
  // language related logic
  const browserLanguage = window.navigator.language.split("-")[0];
  const availableLanguages = ["en", "de", "it"];
  const isBrowserLanguageAvailable =
    availableLanguages.includes(browserLanguage);
  const selectedLanguage = LocalStorage.getItem("selectedLanguage");
  const token = storage.getToken();
  const [existingLanguages, setExistingLanguages] = useState("");

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    } else if (isBrowserLanguageAvailable) {
      i18n.changeLanguage(browserLanguage);
      LocalStorage.setItem("selectedLanguage", browserLanguage);
    } else {
      const defaultLanguage = "en";
      i18n.changeLanguage(defaultLanguage);
      LocalStorage.setItem("selectedLanguage", defaultLanguage);
    }
  }, [browserLanguage, isBrowserLanguageAvailable, selectedLanguage]);

  const addLanguageToDatabase = async (newLanguage) => {
    try {
      await languageApi.addLanguage({ language: newLanguage });
    } catch (error) {
      console.error("Error adding new language:", error);
    }
  };

  const updateLanguageInDatabase = async (updatedLanguage) => {
    try {
      await languageApi.updateLanguage({ language: updatedLanguage });
    } catch (error) {
      console.error("Error updating language:", error);
    }
  };

  useEffect(() => {
    // Fetch existing languages from the database
    if (token) {
      const fetchLanguages = async () => {
        try {
          const response = await languageApi.getLanguage();
          if (response.data.success) {
            setExistingLanguages(response.data.result[0].language);
          }
        } catch (error) {
          console.error("Error fetching existing languages:", error);
        }
      };
      fetchLanguages();
    }
  }, [token, existingLanguages]);

  useEffect(() => {
    if (token) {
      if (!existingLanguages) {
        addLanguageToDatabase(selectedLanguage);
      }

      if (existingLanguages !== selectedLanguage) {
        updateLanguageInDatabase(selectedLanguage);
      }
    }
  }, [token, existingLanguages]);

  // useEffect(() => {
  //   const handlePopState = () => {
  //     dispatch(updateToggleState(false));
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="App">
        <Routes>
          <Route path="/" element={<NewLoginPage />} />
          <Route path="/signup" element={<NewSignupPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/mySites" element={<MyWebsites />} />

            {(userRole === 1 ||
              userRole === 2 ||
              userRole === 7 ||
              userRole === 5) && (
              <>
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/page/*" element={<PageHandler />} />
                <Route path="/tag/manager/settings" element={<TagManager />} />
                <Route path="/uploadimage" element={<UploadImages />} />
                <Route path="/personas" element={<Personas />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/dataupload" element={<DataUpload />} />
                <Route path="/dataexport" element={<DataExport />} />
                <Route path="/manage/api" element={<ManageApi />} />
                <Route
                  path="/manage/api/service"
                  element={<ManageApiService />}
                />
                <Route path="/add/networks" element={<AddAdNetworks />} />
                <Route path="/manage/campaign" element={<ManageCampaign />} />
                <Route
                  path="/manage/add-campaign"
                  element={<AddCampaignComponent />}
                />
                <Route
                  path="/manage/generate-campaign"
                  element={<GeneratedDesignsComponent />}
                />
                <Route path="/manage/edit-design" element={<EditDesign />} />
                <Route
                  path="/manage/design-preview"
                  element={<UsedDesignPreviewComponent />}
                />
                <Route
                  path="/manage/generated-campaign-preview"
                  element={<GeneratedCampaignFinal />}
                />
                <Route
                  path="/manage/saved-campaign"
                  element={<SavedCampaign />}
                />

                <Route path="/manage/widgets" element={<ManageCtaWidgets />} />
                <Route
                  path="/widget/performance"
                  element={<WidgetPerformance />}
                />
                <Route
                  path="/campaign/performance"
                  element={<CampaignPerformance />}
                />
                <Route path="/isichat" element={<IsiChat />} />
                <Route path="/pms" element={<PmsDashboard />} />
              </>
            )}
            {userRole === 3 && (
              <Route path="/settings/*" element={<Settings />} />
            )}
            {userRole === 3 && (
              <>
                {/* Render a restricted page for user role 3 */}
                <Route path="/analytics" element={<RestrictedAccess />} />
                <Route
                  path="/tag/manager/settings"
                  element={<RestrictedAccess />}
                />
              </>
            )}
            {userRole === 4 && (
              <>
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/isichat" element={<IsiChat />} />
                <Route path="/personas" element={<Personas />} />
                <Route path="/pms" element={<PmsDashboard />} />
              </>
            )}
            {userRole === 6 && (
              <>
                <Route path="/settings/*" element={<Settings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/isichat" element={<IsiChat />} />
                <Route path="/personas" element={<Personas />} />
                <Route path="/pms" element={<PmsDashboard />} />
              </>
            )}
            {websitesList?.length === 0 && (
              <Route path="/settings/*" element={<Settings />} />
            )}
            {(userRole === 4 || userRole === 6) && (
              <Route
                path="/tag/manager/settings"
                element={<RestrictedAccess />}
              />
            )}
          </Route>
        </Routes>
        {!toggleState && <Footer />}
      </div>
      <RememberMeLogin />
    </BrowserRouter>
  );
}

export default App;
