import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import deleteIcon from "../assets/delete-icon.svg";
import filtersIcon from "../assets/filters-icon.svg";
import "../assets/filterpill.scss";
import {
  updateSelectedFilters,
  updateSelectedPMSFilters,
  updateSelectedPersonasFilters,
  updateSelectedISIFilters,
  updateSelectedCampPerfomanceFilters
} from "../../../store/slices/generalSlice";
import { updateSelectedRange } from "../../../store/slices/isiChatDataSlice";

export default function FilterPill({ dashboard, filterValues }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);
  const [filtersList, setFiltersList] = useState([]);

  useEffect(() => {
    setFiltersList(
      Object.keys(filterValues).filter((key) => {
        if (dashboard === "analytics" || dashboard === "personas") {
          return key !== t("analyticsPage.worldTitle") && key !== "Filter";
        }
        return true;
      })
    );
  }, [filterValues, dashboard, t]);

  useEffect(() => {
    if (filtersList.length < 3) {
      setPopup(false);
    }
  }, [filtersList]);

  const handleRemoveFilter = (filter) => {
    const updatedFilters = { ...filterValues };
    delete updatedFilters[filter];

    switch (dashboard) {
      case "analytics":
        if (filter === t("analyticsPage.countryTitle")) {
          delete updatedFilters[t("analyticsPage.worldTitle")];
        }
        dispatch(updateSelectedFilters(updatedFilters));
        break;
      case "pms":
        dispatch(updateSelectedPMSFilters(updatedFilters));
        break;
      case "personas":
        if (filter === t("analyticsPage.countryTitle")) {
          delete updatedFilters[t("analyticsPage.worldTitle")];
        }
        dispatch(updateSelectedPersonasFilters(updatedFilters));
        break;
      case "isichat":
        dispatch(updateSelectedISIFilters(updatedFilters));
        dispatch(updateSelectedRange(""));
        break;
      case "campaignPerformance":
        dispatch(updateSelectedCampPerfomanceFilters(updatedFilters));
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    switch (dashboard) {
      case "analytics":
        dispatch(updateSelectedFilters({}));
        break;
      case "pms":
        dispatch(updateSelectedPMSFilters({}));
        break;
      case "personas":
        dispatch(updateSelectedPersonasFilters({}));
        break;
      case "isichat":
        dispatch(updateSelectedISIFilters({}));
        dispatch(updateSelectedRange(""));
        break;
      case "campaignPerformance":
        dispatch(updateSelectedCampPerfomanceFilters({}));
        break;
      default:
        break;
    }
    setPopup(false);
  };

  const renderFilter = (filter) => (
    <div className="filter-pill" key={filter}>
      <div className="selected-name">
        <span className="filter">{filter} - </span>
        {filterValues[filter]?.join(", ")}
      </div>
      <button
        type="button"
        className="delete-button"
        onClick={() => handleRemoveFilter(filter)}
      >
        <img src={deleteIcon} alt="delete-icon" height="22px" width="22px" />
      </button>
    </div>
  );

  return (
    <div className="filter-pill-parent flex">
      {filtersList.slice(0, 2).map(renderFilter)}
      {filtersList.length > 2 && (
        <button
          className="filter-pill popup-button"
          type="button"
          onClick={() => setPopup(!popup)}
        >
          <img
            src={filtersIcon}
            alt="filters-icon"
            height="25px"
            width="25px"
          />
        </button>
      )}
      {popup && (
        <div className="popup-filter-list background-box">
          {filtersList.slice(2).map(renderFilter)}
          <hr />
          <button
            type="button"
            className="clear-button"
            onClick={handleClearAll}
          >
            Clear all filters
          </button>
        </div>
      )}
    </div>
  );
}

FilterPill.propTypes = {
  dashboard: PropTypes.string,
  filterValues: PropTypes.oneOfType([PropTypes.object])
};
